var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Лицензии","filter":"","url-update":"/license/update/:id","store-module":"license","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Дата активации', value: 'created_at_datetime', width: 1 },
        { text: 'Ключ', value: 'code.value', width: 360},
        { text: 'Тип лицензии', value: 'code.license_type.name', width: 1, sortable: false },
        { text: 'Логин', value: 'owner_login' },
        { text: 'ФИО', value: 'name' },
        { text: 'Организация', value: 'company' },
        { text: 'E-mail', value: 'email' },
        { text: 'Адрес', value: 'address' },
        { text: '', value: 'downloadOfflineRegcode', width: 1, sortable: false },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems},on:{"download-offline-regcode":_vm.download},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ID","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","color":_vm.$const.color.primary,"clearable":"","label":"Дата активации","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(filter.created_at),callback:function ($$v) {_vm.$set(filter, "created_at", $$v)},expression:"filter.created_at"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datepicker.filter.created_at),callback:function ($$v) {_vm.$set(_vm.datepicker.filter, "created_at", $$v)},expression:"datepicker.filter.created_at"}},[_c('v-date-picker',{attrs:{"color":_vm.$const.color.primary,"no-title":""},on:{"input":function($event){_vm.datepicker.filter.created_at = false}},model:{value:(filter.created_at),callback:function ($$v) {_vm.$set(filter, "created_at", $$v)},expression:"filter.created_at"}})],1)],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Ключ","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.code),callback:function ($$v) {_vm.$set(filter, "code", $$v)},expression:"filter.code"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[
                    {
                        text: 'Все',
                        value: null
                    } ].concat( _vm.license_type
                  ),"label":"Тип лицензии"},model:{value:(filter.license_type_id),callback:function ($$v) {_vm.$set(filter, "license_type_id", $$v)},expression:"filter.license_type_id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ФИО","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Организация","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.company),callback:function ($$v) {_vm.$set(filter, "company", $$v)},expression:"filter.company"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"E-mail","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.email),callback:function ($$v) {_vm.$set(filter, "email", $$v)},expression:"filter.email"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Адрес","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.address),callback:function ($$v) {_vm.$set(filter, "address", $$v)},expression:"filter.address"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }