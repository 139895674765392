<template>
    <grid-view 
        title="Лицензии"
        filter
        url-update="/license/update/:id"
        store-module="license"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Дата активации', value: 'created_at_datetime', width: 1 },
            { text: 'Ключ', value: 'code.value', width: 360},
            { text: 'Тип лицензии', value: 'code.license_type.name', width: 1, sortable: false },
            { text: 'Логин', value: 'owner_login' },
            { text: 'ФИО', value: 'name' },
            { text: 'Организация', value: 'company' },
            { text: 'E-mail', value: 'email' },
            { text: 'Адрес', value: 'address' },
            { text: '', value: 'downloadOfflineRegcode', width: 1, sortable: false },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
        @download-offline-regcode="download"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                        dense
                      v-model="filter.active"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: false
                      },{
                        text: 'Да',
                        value: true
                      }]"
                      label="Активность"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.id"
                        clearable
                        autocomplete="new-email"
                        label="ID" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-menu 
                        v-model="datepicker.filter.created_at" 
                        :close-on-content-click="false" 
                        transition="scale-transition" 
                        offset-y 
                        max-width="290px" 
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                dense
                                v-model="filter.created_at" 
                                :color="$const.color.primary"
                                clearable
                                label="Дата активации" 
                                persistent-hint 
                                prepend-icon="mdi-calendar" 
                                readonly 
                                v-bind="attrs" 
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker 
                            v-model="filter.created_at" 
                            :color="$const.color.primary"
                            no-title 
                            @input="datepicker.filter.created_at = false" 
                        />
                    </v-menu>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.code"
                        clearable
                        autocomplete="new-email"
                        label="Ключ" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.license_type_id"
                      :color="$const.color.primary"
                      :items="[
                        {
                            text: 'Все',
                            value: null
                        },
                        ...license_type
                      ]"
                      label="Тип лицензии"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.name"
                        clearable
                        autocomplete="new-email"
                        label="ФИО" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.company"
                        clearable
                        autocomplete="new-email"
                        label="Организация" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.email"
                        clearable
                        autocomplete="new-email"
                        label="E-mail" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.address"
                        clearable
                        autocomplete="new-email"
                        label="Адрес" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import DataListMixin from '@/mixins/DataListMixin.vue'
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'UserIndex',
    components: { GridView }, 
    mixins: [DataListMixin],
    data() {
        return {
            license_type: [],
            datepicker: {
                filter: {
                    created_at: false
                }
            }
        }
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');
                // Created at
                // _.set(item, 'created_at', item.active === true ? 'Да' : 'Нет');
                // Contacts
                
                // Owner
                
                return item;
            })
        },
        download(item) {
            const a = document.createElement('a');
            const blob = new Blob([item.offlineRegcode], {'type':'application/octet-stream'});
            a.href = window.URL.createObjectURL(blob);
            a.download = `${item.code.value}.vprocr`;
            a.click();
        }
    },
    async created() {
        this.license_type = await this.getDataList('license_type', {
            label: 'name',
            value: 'id'
        });
    }
}
</script>